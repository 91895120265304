import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useGetOwnersByFilter({
	impersonationContext,
	onError,
	onSuccess,
	filterRequest,
	enabled = true,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.IPagedCollection<Api.IUser>) => void;
	filterRequest: Api.IContactsFilterRequest;
	enabled?: boolean;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		refetchOnWindowFocus: false,
		queryKey: ['user/ownersByFilter', filterRequest],
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.IUser>>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `user/ownersByFilter`,
				}),
				'POST',
				filterRequest
			);
		},
		onError,
		onSuccess,
	});
}
