import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_QUERY_KEY = 'tag/autocomplete/v2';

export function useTagAutocompleteV2Query({
	enabled,
	query,
	category,
	pageSize = 25,
	enforcePageSize,
	excludeHidden,
}: {
	enabled?: boolean;
	query: string;
	category?: string;
	pageSize?: number;
	excludeHidden?: boolean;
	enforcePageSize?: boolean;
}) {
	const userSession = useUserSession();
	return useInfiniteQuery({
		enabled,
		queryFn: ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.AccountTag>>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: 'tag/autocomplete/v2',
					queryParams: {
						query,
						category,
						pageSize,
						excludeHidden,
						enforcePageSize,
						pageToken: pageParam,
					},
				}),
				'GET'
			);
		},
		getNextPageParam: lastPage => lastPage.pageToken,
		queryKey: [BASE_QUERY_KEY, query, category, excludeHidden, pageSize, enforcePageSize],
	});
}
