import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useAutomationTemplateSendToHouseholdMutation({
	onSuccess,
	onError,
}: {
	onSuccess?: (data: Api.IAutomationTemplate) => void;
	onError?: (error: Api.IOperationResultNoValue) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({
			id,
			sendToHousehold,
			impersonationContext,
		}: {
			id: string;
			sendToHousehold: boolean;
			impersonationContext?: Api.IImpersonationContext;
		}) => {
			return userSession.webServiceHelper.callAsync<Api.IAutomationTemplate>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `automationTemplate/${encodeURIComponent(id)}/sendToHousehold`,
					queryParams: {
						sendToHousehold,
					},
					impersonationContext,
				}),
				'PUT'
			);
		},
		onSuccess,
		onError,
	});
}
