import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const usePostExternalIntegrationConfigurationMutation = ({
	onError,
	onSuccess,
	impersonationContext,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
	impersonationContext?: Api.IImpersonationContext;
} = {}) => {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: ({
			integrationProvider,
			configuration,
		}: {
			integrationProvider: Api.IntegrationProvider;
			configuration: Partial<Api.IIntegrationConfiguration>;
		}) => {
			const body = {
				...configuration,
				_type: `${integrationProvider}IntegrationConfiguration`,
			};
			return userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `integrations/${integrationProvider}/configuration`,
				}),
				'POST',
				body
			);
		},
		onError,
		onSuccess,
	});
};
