import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const updateOptionsAsync = ({
	userSession,
	impersonationContext,
	options,
	eventId,
	fieldId,
}: {
	userSession: Api.UserSessionContext;
	impersonationContext?: Api.IImpersonationContext;
	options: Api.CustomFormFieldOption[];
	eventId: string;
	fieldId: string;
}) => {
	return userSession.webServiceHelper.callAsync<Api.ISatisfactionSurvey>(
		Api.ImpersonationBroker.composeApiUrl({
			impersonationContext,
			urlPath: `survey/satisfaction/${eventId}/customForm/field/${fieldId}/options`,
			queryParams: { expand: 'options' },
		}),
		'PUT',
		options
	);
};

interface UpdateMutationParams {
	fields: Api.IFormField<string>[];
	eventId: string;
	impersonationContext?: Api.IImpersonationContext;
}

export function useUpdateSatisfactionSurveyCustomFormFieldOptionsMutation({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (result: [Api.ISatisfactionSurvey[], Api.IOperationResultNoValue[]]) => void;
}) {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: async ({
			impersonationContext,
			fields,
			eventId,
		}: UpdateMutationParams): Promise<[Api.ISatisfactionSurvey[], Api.IOperationResultNoValue[]]> => {
			const responses = await Promise.allSettled(
				fields.map(field => {
					return updateOptionsAsync({
						userSession,
						impersonationContext,
						options: field.options,
						eventId,
						fieldId: field.id,
					});
				})
			);

			const errors: PromiseRejectedResult[] = [];
			const results: Api.ISatisfactionSurvey[] = [];
			responses.forEach(result => {
				if (result.status === 'rejected') {
					errors.push(result as PromiseRejectedResult);
				} else {
					results.push((result as PromiseFulfilledResult<Api.ISatisfactionSurvey>).value);
				}
			});

			return [results, errors.map(x => Api.asApiError(x.reason || x.status))] as const;
		},
		onError,
		onSuccess,
	});
}
