import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { ContactFilterApproximation } from '../../extViewmodels/sdk/models/Contact';
import { ContactFilterRequest } from '../../extViewmodels/sdk/models/requests/ContactFilterRequest';
import { useUserSession } from '../../models/hooks/appStateHooks';
const BASE_API_KEY = '/contact/approximate';

export const useContactFilterApproximationQuery = ({
	filterRequest,
	enabled,
	sendType,
	impersonationContext,
	impUserId,
	refetchOnWindowFocus = false,
}: {
	filterRequest: ContactFilterRequest;
	enabled?: boolean;
	sendType: Api.ContactEmailApproximation;
	impersonationContext?: Api.IImpersonationContext;
	impUserId?: string;
	refetchOnWindowFocus?: boolean;
}) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: async () => {
			const data = await userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `contact/filter/approximate/${sendType}${impUserId ? `?impUserId=${impUserId}` : ''}`,
				}),
				'POST',
				filterRequest
			);
			return data as ContactFilterApproximation;
		},
		queryKey: [BASE_API_KEY, filterRequest, sendType],
		refetchOnWindowFocus,
	});
};
