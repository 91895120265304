import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../../web/ReactQueryProvider';

const USER_OPERATIONS_QUERY_KEY = 'user-operations/byAccount';
const DEFAULT_PAGE_SIZE = 100;

export function useUserOperationsQuery({
	onError,
	accountId,
	pageSize = DEFAULT_PAGE_SIZE,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	accountId: string;
	pageSize?: number;
}) {
	const userSession = useUserSession();
	return useQuery({
		onError,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IUserWithStats[]>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: {
						accountId,
						pageSize,
						expand: 'Stats',
					},
					urlPath: 'admin/userOperations/byAccount',
				}),
				'GET'
			);
		},
		refetchOnWindowFocus: false,
		queryKey: [USER_OPERATIONS_QUERY_KEY, accountId],
	});
}

export const invalidateUserOperationsQuery = ({
	accountId,
}: {
	accountId: string;
}) => reactQueryClient.invalidateQueries([USER_OPERATIONS_QUERY_KEY, accountId]);
