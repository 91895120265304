import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'board/id';

export function useGetBoard({
	boardId,
}: {
	boardId: string;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled: !!boardId,
		queryFn: async () => {
			const result = await userSession.webServiceHelper.callAsync<Api.IBoard>(`board/${boardId}`, 'GET');
			return result;
		},
		queryKey: [BASE_QUERY_KEY, boardId],
		refetchOnWindowFocus: false,
	});
}

export const invalidateBoardQuery = (boardId: string) => {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY, boardId]);
};
