import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const emptyContent = {
	document: '<lev-content></lev-content>',
	documentVersion: 1,
};

export const useTemplateCardCreateLogo = ({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.IBulkOperationResult<Api.IFileAttachment>) => void;
}) => {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: async ({
			attachments,
			attachmentId,
			name,
			providerProductIds,
		}: {
			attachments: Api.IFileAttachment[];
			attachmentId: string;
			name: string;
			providerProductIds: Record<Api.HandwrittenCardProvider, string>;
		}) => {
			return userSession.webServiceHelper.callAsync<Api.IBulkOperationResult<Api.IFileAttachment>>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `template/card`,
				}),
				'POST',
				{
					attachments,
					imageTypes: {
						Face: attachmentId,
					},
					name,
					templateType: 9,
					content: emptyContent,
					providerProductIds,
				}
			);
		},
		onError,
		onSuccess,
	});
};
