import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useToaster, useUserSession } from '../../models/hooks/appStateHooks';

export function useRegisterUserMutation({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.IUser) => void;
}) {
	const userSession = useUserSession();
	const toaster = useToaster();

	return useMutation({
		mutationFn: ({
			accountId,
			user,
			sendWelcomeEmail = false,
		}: {
			accountId: string;
			user: Partial<Api.IUser>;
			sendWelcomeEmail?: boolean;
		}) => {
			return userSession.webServiceHelper.callAsync<Api.IUser>(
				`user/register?sendWelcomeEmail=${sendWelcomeEmail}`,
				'POST',
				{
					...user,
					accountId,

					email: user.primaryEmail.value,
				}
			);
		},
		onError: (error: Api.IOperationResultNoValue) => {
			onError?.(error);
			toaster.push({
				message: error.systemMessage,
				type: 'errorMessage',
			});
		},
		onSuccess,
	});
}
