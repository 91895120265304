import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useToaster, useUserSession } from '../../models/hooks/appStateHooks';

export const usePostExternalIntegrationMainSettingsMutation = ({
	onError,
	onSuccess,
	impersonationContext,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
	impersonationContext?: Api.IImpersonationContext;
} = {}) => {
	const userSession = useUserSession();
	const toaster = useToaster();
	return useMutation({
		mutationFn: ({
			integrationProvider,
			mainSettings,
		}: {
			integrationProvider: Api.IntegrationProvider;
			mainSettings: Partial<Api.IIntegrationSettings>;
		}) => {
			return userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `integrations/${integrationProvider}/settings`,
				}),
				'POST',
				{
					...mainSettings,
					_type: `${integrationProvider}IntegrationSettings`,
				}
			);
		},
		onError: (error: Api.IOperationResultNoValue) => {
			onError?.(error);
			toaster.push({
				message: error.systemMessage,
				type: 'errorMessage',
			});
		},
		onSuccess: () => {
			onSuccess?.();
			toaster.push({
				message: 'Settings updated',
				type: 'successMessage',
			});
		},
	});
};
