import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { ContactSortKey } from '../../models';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_API_KEY = '/contact/filter/v2';

export const useContactFilterQuery = ({
	expand,
	pageSize = 25,
	sort = 'asc',
	sortBy = ContactSortKey.Handle,
	filterRequest,
	enabled,
	impersonationContext,
	refetchOnWindowFocus = false,
}: {
	expand?: string;
	pageSize?: number;
	sort?: 'asc' | 'desc';
	sortBy?: ContactSortKey;
	filterRequest: Api.IBulkContactsRequest;
	enabled?: boolean;
	impersonationContext?: Api.IImpersonationContext;
	refetchOnWindowFocus?: boolean;
}) => {
	const userSession = useUserSession();
	return useInfiniteQuery({
		enabled,
		queryFn: async ({ pageParam }) => {
			const value = await userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					queryParams: { expand, pageSize, pageToken: pageParam, sort, sortBy },
					urlPath: `contact/filter/v2`,
				}),
				'POST',
				filterRequest
			);
			return value as Api.IPagedCollection<Api.IContact>;
		},
		getNextPageParam: lastPage => lastPage.pageToken,
		queryKey: [BASE_API_KEY, filterRequest, sortBy, sort, pageSize].concat(expand ? expand : []),
		refetchOnWindowFocus,
	});
};
