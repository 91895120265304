import * as Api from '@ViewModels';
import { useQueries, useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'boardStageItemsSearch';

const getQueryFn =
	({
		stageId,
		searchRequest,
		userSession,
	}: {
		stageId: string;
		searchRequest: Api.IOpportunitySearchRequest;
		userSession: Api.UserSessionContext;
	}) =>
	async () => {
		return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.IOpportunity>>(
			`board/stage/${stageId}/items/search`,
			'POST',
			searchRequest
		);
	};

export function useBoardStageItemsSearch({
	stageId,
	searchRequest,
	enabled = true,
	refetchInterval,
	userSession,
}: {
	stageId: string;
	searchRequest: Api.IOpportunitySearchRequest;
	enabled?: boolean;
	refetchInterval?: number;
	userSession: Api.UserSessionContext;
}) {
	return useQuery({
		enabled,
		queryFn: getQueryFn({ stageId, searchRequest, userSession }),
		queryKey: [BASE_QUERY_KEY, stageId, searchRequest],
		refetchOnWindowFocus: false,
		refetchInterval,
	});
}

export const invalidateBoardStageItemsSearch = (stageId: string, searchRequest: Api.IOpportunitySearchRequest) => {
	reactQueryClient.invalidateQueries({
		queryKey: [BASE_QUERY_KEY, stageId, searchRequest],
	});
};

export const invalidateAllBoardStageItemsSearch = () => {
	reactQueryClient.invalidateQueries({
		queryKey: [BASE_QUERY_KEY],
	});
};

export const useBoardStageItemsSearchMultiple = (
	stageIds: string[],
	searchRequest: Api.IOpportunitySearchRequest,
	enabled: boolean,
	refetchInterval?: number
) => {
	const userSession = useUserSession();
	return useQueries({
		queries: stageIds.map(stageId => {
			return {
				enabled,
				queryFn: getQueryFn({ stageId, searchRequest, userSession }),
				queryKey: [BASE_QUERY_KEY, stageId, searchRequest],
				refetchInterval,
			};
		}),
	});
};

export const invalidateBoardStageItemsSearchMultiple = () => {
	reactQueryClient.invalidateQueries({
		queryKey: [BASE_QUERY_KEY],
	});
};
