import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const INTEGRATIONS_QUERY_KEY = 'integrations';

export function useGetExternalIntegrationsQuery({
	impersonationContext,
	onError,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
}) {
	const userSession = useUserSession();
	return useQuery({
		onError,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IExternalIntegration[]>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `integrations`,
				}),
				'GET'
			);
		},
		queryKey: [INTEGRATIONS_QUERY_KEY, impersonationContext],
	});
}

export const invalidateIntegrationsQuery = ({
	impersonationContext,
}: {
	impersonationContext: Api.IImpersonationContext;
}) => reactQueryClient.invalidateQueries([INTEGRATIONS_QUERY_KEY, impersonationContext]);
