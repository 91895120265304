import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const usePostExternalIntegrationStartStageMutation = ({
	onError,
	onSuccess,
	impersonationContext,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
	impersonationContext?: Api.IImpersonationContext;
} = {}) => {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: ({
			etlStage,
			integrationProvider,
		}: {
			etlStage: Api.EtlStage;
			integrationProvider: Api.IntegrationProvider;
		}) => {
			return userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `integrations/${integrationProvider}/${etlStage}`,
				}),
				'POST'
			);
		},
		onError,
		onSuccess,
	});
};
