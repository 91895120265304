import { useQuery } from '@tanstack/react-query';
import * as Api from '@ViewModels';
import { TelephonyServiceProviderConfiguration } from '../../extViewmodels/sdk/models/Telephony';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

export const TELEPHONY_CONFIGURATION_QUERY_KEY = 'telephonyConfigurationQueryKey';

export function useTelephonyConfigurationQuery({
	refetchOnWindowFocus = false,
	retry = false,
	impersonationContext,
	enabled,
}: {
	enabled?: boolean;
	refetchOnWindowFocus?: boolean;
	retry?: boolean;
	impersonationContext?: Api.IImpersonationContext;
} = {}) {
	const userSession = useUserSession();
	return useQuery({
		queryFn: async () => {
			try {
				return await userSession.webServiceHelper.callAsync<TelephonyServiceProviderConfiguration>(
					Api.ImpersonationBroker.composeApiUrl({
						urlPath: 'telephony/configuration',
						impersonationContext,
					}),
					'GET'
				);
			} catch (error) {
				if (typeof error === 'object' && error != null && 'systemCode' in error && error.systemCode === 404) {
					return null;
				}
				throw error;
			}
		},
		queryKey: [TELEPHONY_CONFIGURATION_QUERY_KEY, impersonationContext],
		refetchOnWindowFocus,
		retry,
		enabled,
	});
}

export const invalidateTelephonyGetConfiguration = () => {
	reactQueryClient.invalidateQueries([TELEPHONY_CONFIGURATION_QUERY_KEY]);
};

export const resetTelephonyGetConfiguration = () => {
	reactQueryClient.resetQueries({ queryKey: [TELEPHONY_CONFIGURATION_QUERY_KEY] });
};
