import { useUserSession } from '../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';

const BASE_QUERY_KEY = 'board/id/items';

export function useGetOpportunity({
	opportunityId,
}: {
	opportunityId: string;
}) {
	const userSession = useUserSession();
	return useQuery({
		queryFn: async () => {
			const result = await userSession.webServiceHelper.callAsync<Api.IOpportunity>(
				`board/item/${opportunityId}`,
				'GET'
			);
			return result;
		},
		queryKey: [BASE_QUERY_KEY, opportunityId],
		refetchOnWindowFocus: false,
	});
}
