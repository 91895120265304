import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { ITemplateCard, ImpersonationBroker } from '../../viewmodels/AppViewModels';
import { reactQueryClient } from '../../web/ReactQueryProvider';

export const EMAIL_SEARCH_TEMPLATES_QUERY_KEY = 'emailSearchTemplates';

export const useEmailSearchTemplates = ({
	industry,
	query,
	excludeExpired = true,
	sortBy = 'lastModifiedDate',
	enabled,
	onError,
	impersonationContext,
}: {
	query: string;
	industry: Api.Industry;
	enabled?: boolean;
	excludeExpired?: boolean;
	sortBy?: string;
	onError?: (err: Api.IOperationResultNoValue) => void;
	impersonationContext?: Api.IImpersonationContext;
}) => {
	const userSession = useUserSession();
	const search = query.trim();
	return useQuery({
		enabled,
		onError,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<ITemplateCard[]>(
				ImpersonationBroker.composeApiUrl({
					queryParams: {
						excludeExpired,
						search,
						sortBy,
					},
					urlPath: `template/${encodeURIComponent(industry)}/filter`,
					impersonationContext,
				}),
				'GET'
			);
		},
		queryKey: [EMAIL_SEARCH_TEMPLATES_QUERY_KEY, industry, excludeExpired, search, sortBy, impersonationContext],
	});
};

export const invalidateEmailSearchTemplates = () => {
	reactQueryClient.invalidateQueries([EMAIL_SEARCH_TEMPLATES_QUERY_KEY]);
};
