import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useCreateOpportunityMutation = ({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.IOpportunity) => void;
} = {}) => {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: (opportunity: Api.IOpportunity) => {
			const stageId = opportunity.boardStage.id;

			return userSession.webServiceHelper.callAsync<Api.IOpportunity>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `board/stage/${stageId}/opportunity`,
				}),
				'POST',
				opportunity
			);
		},
		onError,
		onSuccess,
	});
};
