import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { IMoveBoardItem } from '../../extViewmodels/sdk/models';
import { useUserSession } from '../../models/hooks/appStateHooks';
export const useMoveItemToStageMutation = ({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.IBoard) => void;
} = {}) => {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: ({
			opportunityId,
			moveBoardItem,
		}: {
			opportunityId: string;
			moveBoardItem: IMoveBoardItem;
		}) => {
			return userSession.webServiceHelper.callAsync<Api.IBoard>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `board/item/${opportunityId}/move`,
				}),
				'POST',
				moveBoardItem
			);
		},
		onError,
		onSuccess,
	});
};
