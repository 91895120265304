import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

export const BASE_QUERY_KEY = 'automationTemplate/resourceSelector/<id>/defaultTemplateId';

export const useAutomationTemplateResourceSelectorDefaultTemplateIdQuery = ({
	resourceSelectorId,
	onError,
	enabled,
}: {
	enabled?: boolean;
	resourceSelectorId: Api.ResourceSelectorId;
	onError?: (err: Api.IOperationResultNoValue) => void;
}) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		onError,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<string>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `automationTemplate/resourceSelector/${resourceSelectorId}/defaultTemplateId`,
				}),
				'GET'
			);
		},
		queryKey: [BASE_QUERY_KEY, resourceSelectorId],
	});
};

export const invalidateAutomationId = () => {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY]);
};
