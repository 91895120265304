import * as Api from '@ViewModels';
import { useQueries, useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'boardStageItems';

const getQueryFn =
	({
		stageId,
		userSession,
	}: {
		stageId: string;
		userSession: Api.UserSessionContext;
	}) =>
	async () => {
		return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.IOpportunity>>(
			Api.ImpersonationBroker.composeApiUrl({
				urlPath: `board/stage/${stageId}/items`,
			}),
			'GET'
		);
	};

export function useBoardStageItems({
	enabled = true,
	stageId,
	refetchInterval,
}: {
	enabled?: boolean;
	stageId: string;
	refetchInterval?: number;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: getQueryFn({ stageId, userSession }),
		queryKey: [BASE_QUERY_KEY, stageId],
		refetchOnWindowFocus: false,
		refetchInterval,
	});
}

export const invalidateBoardStageItems = (stageId: string) => {
	reactQueryClient.invalidateQueries({
		queryKey: [BASE_QUERY_KEY, stageId],
	});
};

export const invalidateAllBoardStageItems = () => {
	reactQueryClient.invalidateQueries({
		queryKey: [BASE_QUERY_KEY],
	});
};

export const useBoardStageItemsMultiple = (stageIds: string[], enabled: boolean, refetchInterval?: number) => {
	const userSession = useUserSession();
	return useQueries({
		queries: stageIds.map(stageId => {
			return {
				enabled,
				queryFn: getQueryFn({ stageId, userSession }),
				queryKey: [BASE_QUERY_KEY, stageId],
				refetchInterval,
			};
		}),
	});
};
