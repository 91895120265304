import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const USER_QUERY_KEY = 'user_admins';

export function useAdminUsersQuery({
	impersonationContext,
	onError,
	onSuccess,
	enabled = true,
	refetchOnWindowFocus = false,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (user: Api.IUser[]) => void;
	enabled?: boolean;
	refetchOnWindowFocus?: boolean;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		refetchOnWindowFocus,
		onError,
		onSuccess,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IUser[]>(
				Api.ImpersonationBroker.composeApiUrl({ impersonationContext, urlPath: `user/admins` }),
				'GET'
			);
		},
		queryKey: [USER_QUERY_KEY, impersonationContext],
		retry: 1,
	});
}
