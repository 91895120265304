import * as Api from '@ViewModels';
import { useQueries, useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_QUERY_KEY = 'template';

export function useTemplate<T extends Api.ITemplate>({
	templateId,
	enabled,
	impersonationContext,
}: {
	templateId: string;
	enabled?: boolean;
	impersonationContext?: Api.IImpersonationContext;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: () =>
			userSession.webServiceHelper.callAsync<T>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `template/${encodeURIComponent(templateId)}`,
				}),
				'GET'
			),
		queryKey: [BASE_QUERY_KEY, { templateId }],
	});
}

export function useTemplateQueries<T extends Api.ITemplate>({
	templateIds,
	refetchOnWindowFocus = true,
	queryKeyProvider,
	impersonationContext,
}: {
	templateIds: string[];
	refetchOnWindowFocus?: boolean;
	queryKeyProvider?: (templateId: string, index: number) => string;
	impersonationContext?: Api.IImpersonationContext;
}) {
	const userSession = useUserSession();
	return useQueries({
		queries: templateIds.map((templateId, index) => {
			return {
				refetchOnWindowFocus,
				queryFn: () =>
					userSession.webServiceHelper.callAsync<T>(
						Api.ImpersonationBroker.composeApiUrl({
							impersonationContext,
							urlPath: `template/${encodeURIComponent(templateId)}`,
							queryParams: {
								expand: 'LastUsedByDate',
							},
						}),
						'GET'
					),
				queryKey: [BASE_QUERY_KEY, { templateId }, queryKeyProvider?.(templateId, index)],
			};
		}),
	});
}
