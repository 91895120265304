import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const INTEGRATION_QUERY_KEY = 'integration';

export function useGetExternalIntegrationQuery({
	impersonationContext,
	provider,
	onError,
	onSuccess,
}: {
	impersonationContext?: Api.IImpersonationContext;
	provider: Api.IntegrationProvider;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
}) {
	const userSession = useUserSession();
	return useQuery({
		onError,
		onSuccess,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IExternalIntegration>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `integrations/${provider}`,
				}),
				'GET'
			);
		},
		refetchOnWindowFocus: false,
		queryKey: [INTEGRATION_QUERY_KEY, provider, impersonationContext],
	});
}

export const invalidateIntegrationQuery = ({
	impersonationContext,
	provider,
}: {
	impersonationContext?: Api.IImpersonationContext;
	provider: Api.IntegrationProvider;
}) => reactQueryClient.invalidateQueries([INTEGRATION_QUERY_KEY, provider, impersonationContext]);

export const invalidateAllIntegrationQueries = () =>
	reactQueryClient.invalidateQueries({ queryKey: [INTEGRATION_QUERY_KEY] });
