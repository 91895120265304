import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

// Mutation despite being a GET because it requires imperative usage.
// Generating the auth URL fires off a systemJob on the platform so using a query to prefetch the URL and potentially not using it is not ideal.
// Should be changed to query if we feel the benefits of prefetching outweigh the cost of firing a systemJob
export function useCallbackAuthorizeUrlMutation({
	onSuccess,
	onError,
}: {
	onSuccess?: (data: string) => void;
	onError?: (error: Api.IOperationResultNoValue) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async ({ provider }: { provider: Api.ConfigurableIntegrationType | Api.IntegrationProvider }) => {
			const data = await userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: { provider: encodeURIComponent(provider) },
					urlPath: 'callback/authorize_url',
				}),
				'GET'
			);
			return data as string;
		},
		onError,
		onSuccess,
	});
}
