import * as PopoverPrimitive from '@radix-ui/react-popover';
import { css, StyleDeclaration, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { aceGray, white } from '../../styles/colors';

const slideInFromTopNFadeNZoom = {
	'0%': {
		transform: 'translateY(-8px) scale(0.95)',
		opacity: 0,
	},
	'100%': {
		transform: 'translateY(0) scale(1)',
		opacity: 1,
	},
};
const slideInFromBottomNFadeNZoom = {
	'0%': {
		transform: 'translateY(8px) scale(0.95)',
		opacity: 0,
	},
	'100%': {
		transform: 'translateY(0) scale(1)',
		opacity: 1,
	},
};
const slideInFromLeftNFadeNZoom = {
	'0%': {
		transform: 'translateX(-8px) scale(0.95)',
		opacity: 0,
	},
	'100%': {
		transform: 'translateX(0) scale(1)',
		opacity: 1,
	},
};
const slideInFromRightNFadeNZoom = {
	'0%': {
		transform: 'translateX(8px) scale(0.95)',
		opacity: 0,
	},
	'100%': {
		transform: 'translateX(0) scale(1)',
		opacity: 1,
	},
};

const fadeOutZoomOut = {
	'0%': {
		transform: 'scale(1)',
		opacity: 1,
	},
	'100%': {
		transform: 'scale(0.95)',
		opacity: 0,
	},
};

const s = StyleSheet.create({
	content: {
		boxSizing: 'border-box',
		zIndex: 5,
		border: `1px solid ${aceGray}`,
		borderRadius: 8,
		backgroundColor: white,
		padding: '8px 16px',
		boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
		outline: 'none',
		animationDuration: '150ms',
		animationTimingFunction: `cubic-bezier(0.4, 0, 0.2, 1)`,
		':is([data-state="closed"])': {
			animationName: [fadeOutZoomOut],
		},
		':is([data-side="bottom"][data-state="open"])': {
			animationName: [slideInFromTopNFadeNZoom],
		},
		':is([data-side="top"][data-state="open"])': {
			animationName: [slideInFromBottomNFadeNZoom],
		},
		':is([data-side="left"][data-state="open"])': {
			animationName: [slideInFromRightNFadeNZoom],
		},
		':is([data-side="right"][data-state="open"])': {
			animationName: [slideInFromLeftNFadeNZoom],
		},
	},
	arrow: {
		fill: white,
		stroke: '#f3f3f3',
		strokeWidth: 1,
		filter: 'drop-shadow(0 1px 1px rgba(162, 162, 162, .5))',
	},
});

const PopoverArrow = React.forwardRef<
	React.ElementRef<typeof PopoverPrimitive.Arrow>,
	React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Arrow> & {
		styles?: StyleDeclaration[];
	}
>(({ className, styles, ...props }, ref) => (
	<PopoverPrimitive.Arrow ref={ref} className={`${css(s.arrow, styles)} ${className}`} {...props} />
));
PopoverArrow.displayName = PopoverPrimitive.Arrow.displayName;

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverAnchor = PopoverPrimitive.Anchor;

const PopoverContent = React.forwardRef<
	React.ElementRef<typeof PopoverPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & {
		styles?: StyleDeclaration[];
	}
>(({ className, styles, align = 'center', sideOffset = 4, ...props }, ref) => (
	<PopoverPrimitive.Portal>
		<PopoverPrimitive.Content
			ref={ref}
			align={align}
			sideOffset={sideOffset}
			className={`${css(s.content, styles)} ${className}`}
			{...props}
		/>
	</PopoverPrimitive.Portal>
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export { Popover, PopoverAnchor, PopoverArrow, PopoverContent, PopoverTrigger };
