import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_QUERY_KEY = 'insurancePolicyLineOfBusiness/autocomplete';

export function useInsurancePolicyLineOfBusinessAutocompleteQuery({
	enabled,
	fragment,
	pageSize = 25,
	enforcePageSize = true,
	searchFields,
}: {
	enabled?: boolean;
	fragment: string;
	pageSize?: number;
	enforcePageSize?: boolean;
	searchFields?: string;
}) {
	const userSession = useUserSession();
	return useInfiniteQuery({
		enabled,
		queryFn: ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.IInsurancePolicyLineOfBusiness>>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: {
						enforcePageSize,
						fragment,
						pageSize,
						searchFields,
						pageToken: pageParam,
					},
					urlPath: 'insurancePolicy/lineOfBusiness/autocomplete',
				}),
				'GET'
			);
		},
		getNextPageParam: lastPage => lastPage.pageToken,
		queryKey: [BASE_QUERY_KEY, fragment, pageSize, enforcePageSize, searchFields],
		refetchOnWindowFocus: false,
	});
}
