import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const usePostExternalIntegrationTagsSettingsMutation = ({
	onError,
	onSuccess,
	impersonationContext,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
	impersonationContext?: Api.IImpersonationContext;
} = {}) => {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: ({
			integrationProvider,
			tagsSettings,
		}: {
			integrationProvider: Api.IntegrationProvider;
			tagsSettings: Partial<Api.ITagSettings>;
		}) => {
			return userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `integrations/${integrationProvider}/settings/tags`,
				}),
				'POST',
				{
					...tagsSettings,
					_type: `${integrationProvider}TagSettings`,
				}
			);
		},
		onError,
		onSuccess,
	});
};
