import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_API_KEY = 'board/items/:id/activity';

export function useGetOpportunityActivitiesQuery({
	opportunityId,
	pageSize = 25,
	sort = 'asc',
	onError,
	refetchOnWindowFocus = false,
}: {
	opportunityId?: string;
	pageSize?: number;
	sort?: 'asc' | 'desc';
	onError?: (error: Api.IOperationResultNoValue) => void;
	refetchOnWindowFocus?: boolean;
}) {
	const userSession = useUserSession();
	return useInfiniteQuery<Api.IPagedCollection<Api.IBoardItemActivity>>({
		enabled: !!opportunityId,
		getNextPageParam: lastPage => lastPage.pageToken,
		queryFn: ({ pageParam }) =>
			userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.IBoardItemActivity>>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: {
						pageSize,
						pageToken: pageParam,
						sort,
					},
					urlPath: `board/item/${opportunityId}/activity`,
				}),
				'GET'
			),
		queryKey: [BASE_API_KEY, sort, pageSize, opportunityId],
		onError,
		refetchOnWindowFocus,
	});
}

export const invalidateGetOpportunityActivities = (opportunityId: string) => {
	reactQueryClient.invalidateQueries([BASE_API_KEY, opportunityId]);
};
