import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'reports/campaign';

export function useInfiniteCampaignReportsQuery({
	pageSize = 25,
	userId,
	impersonationContext,
	enabled,
	endDate,
	startDate,
	filter,
	refetchOnWindowFocus = false,
}: {
	pageSize?: number;
	userId?: string;
	impersonationContext?: Api.IImpersonationContext;
	enabled?: boolean;
	endDate?: Date;
	startDate?: Date;
	filter: Api.IFilterCriteria<Api.BulkEmailFilterProperty, Api.FilterOperator>;
	refetchOnWindowFocus?: boolean;
}) {
	const userSession = useUserSession();

	return useInfiniteQuery<Api.IPagedCollection<Api.ICampaign>>({
		enabled,
		getNextPageParam: lastPage => lastPage.pageToken,
		queryFn: async ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.ICampaign>>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					queryParams: { pageSize, pageToken: pageParam },
					urlPath: `reports/campaign`,
				}),
				'POST',
				{
					endDate,
					filter,
					startDate,
					userId,
				}
			);
		},
		queryKey: [BASE_QUERY_KEY, pageSize, filter, userId, startDate, endDate, impersonationContext],
		refetchOnWindowFocus,
	});
}

export function invalidateInfiniteCampaignReportsQuery({
	pageSize,
	filter,
	userId,
	startDate,
	endDate,
	impersonationContext,
}: {
	pageSize?: number;
	userId?: string;
	filter: Api.IFilterCriteria<Api.BulkEmailFilterProperty, Api.FilterOperator>;
	startDate?: Date;
	endDate?: Date;
	impersonationContext?: Api.IImpersonationContext;
}) {
	return reactQueryClient.invalidateQueries([
		BASE_QUERY_KEY,
		pageSize,
		filter,
		userId,
		startDate,
		endDate,
		impersonationContext,
	]);
}

export function invalidateAllInfiniteCampaignReportQueries() {
	return reactQueryClient.invalidateQueries([BASE_QUERY_KEY]);
}
