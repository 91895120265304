import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';

interface IParams {
	template: Api.IBlogTemplate;
	statuses: Api.ITemplateStatus[];
}
export function useAdminBlogTemplateMutation({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: (res: { template: Api.IBlogTemplate; industries: Record<Api.Industry, Api.TemplateStatus> }) => void;
}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async ({ template, statuses }: IParams) => {
			const value = await userSession.webServiceHelper.callAsync<{
				template: Api.IBlogTemplate;
				industries: Record<Api.Industry, Api.TemplateStatus>;
			}>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `admin/template/industry/blogCategories/templates`,
				}),
				'POST',
				{ template, statuses }
			);
			return value;
		},
		onError,
		onSuccess,
	});
}
