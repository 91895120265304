import { useUserSession } from '../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'board/id/items/search';

export function useSearchOpportunities({
	enabled,
	boardId,
	searchRequest,
	onError,
}: {
	enabled: boolean;
	boardId: string;
	searchRequest: Api.IOpportunitySearchRequest;
	onError?: (err: Api.IOperationResultNoValue) => void;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		onError,
		queryFn: async () => {
			return userSession.webServiceHelper.callAsync<Api.IOpportunity[]>(
				`board/${boardId}/items/search`,
				'POST',
				searchRequest
			);
		},
		queryKey: [BASE_QUERY_KEY, boardId, searchRequest],
		refetchOnWindowFocus: false,
	});
}

export const invalidateSearchOpportunities = ({
	boardId,
	searchRequest,
}: {
	boardId: string;
	searchRequest: Api.IOpportunitySearchRequest;
}) => reactQueryClient.invalidateQueries([BASE_QUERY_KEY, boardId, searchRequest]);
