import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

interface IParams {
	templateId: string;
	impersonationContext?: Api.IImpersonationContext;
}

export function useTemplateExportPDFMutation({
	onSuccess,
	onError,
}: {
	onSuccess?: (data: Api.ISystemJob, variables: IParams) => void;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onMutate?: () => void;
} = {}) {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: ({ templateId, impersonationContext }: IParams) =>
			userSession.webServiceHelper.callAsync<Api.ISystemJob>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `template/${templateId}/pdfGeneration`,
					impersonationContext,
				}),
				`POST`,
				{}
			),
		onError,
		onSuccess,
	});
}
