import { useUserSession } from '../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'board/id/items';

export function useGetBoardItems({
	boardId,
	onError,
}: {
	boardId: string;
	onError?: (err: Api.IOperationResultNoValue) => void;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled: !!boardId,
		onError,
		queryFn: async () => {
			return userSession.webServiceHelper.callAsync<Api.IOpportunity[]>(`board/${boardId}/items`, 'GET');
		},
		queryKey: [BASE_QUERY_KEY, boardId],
		refetchOnWindowFocus: false,
	});
}

export const invalidateGetBoardItems = ({
	boardId,
}: {
	boardId: string;
}) => reactQueryClient.invalidateQueries([BASE_QUERY_KEY, boardId]);

export const invalidateAllGetBoardItems = () => reactQueryClient.invalidateQueries([BASE_QUERY_KEY]);
