import { useMutation } from '@tanstack/react-query';
import * as Api from '@ViewModels';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useUpdateSatisfactionSurveyMutation = ({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data?: Api.ISatisfactionSurvey) => void;
}) => {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ survey }: { survey: Api.ISatisfactionSurvey }) => {
			return userSession.webServiceHelper.callAsync<Api.ISatisfactionSurvey>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `survey/satisfaction/${survey.id}`,
				}),
				'PUT',
				survey
			);
		},
		onError,
		onSuccess,
	});
};
