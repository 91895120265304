import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const QUERY_KEY = 'integration/available-tags';

export function useIntegrationAvailableTagsQuery({
	impersonationContext,
	integrationProvider,
	onError,
}: {
	impersonationContext?: Api.IImpersonationContext;
	integrationProvider: Api.IntegrationProvider;
	onError?: (error: Api.IOperationResultNoValue) => void;
}) {
	const userSession = useUserSession();
	return useQuery({
		refetchOnWindowFocus: false,
		retry: false,
		onError,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<string[]>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `integrations/${integrationProvider}/tags`,
				}),
				'GET'
			);
		},
		queryKey: [QUERY_KEY, impersonationContext, integrationProvider],
	});
}

export const invalidateIntegrationAvailableTagsQuery = ({
	impersonationContext,
	integrationProvider,
}: {
	impersonationContext: Api.IImpersonationContext;
	integrationProvider: Api.IntegrationProvider;
}) => reactQueryClient.invalidateQueries([QUERY_KEY, impersonationContext, integrationProvider]);
