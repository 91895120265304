import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { canSendHtmlNewsletters } from '../../models/UiUtils';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { KnownCategories } from '../../viewmodels/AppViewModels';

export const EMAIL_CAMPAIGN_CATEGORIES_QUERY_KEY = 'emailCampaignCategories';

export const useTemplateIndustryCategoriesQuery = ({
	industry,
	enabled = Boolean(industry),
	impersonationContext,
	onSuccess,
}: {
	impersonationContext?: Api.IImpersonationContext;
	industry: Api.Industry;
	enabled?: boolean;
	onSuccess?: (categories: string[]) => void;
}) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		onSuccess,
		queryFn: async () => {
			let categories = await userSession.webServiceHelper.callAsync<string[]>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `template/${encodeURIComponent(industry)}/categories`,
				}),
				'GET'
			);
			const isFinancial = industry === Api.Industry.Financial;
			categories = categories.filter(
				c => c !== 'Archived' && c !== 'Uncategorized' && (isFinancial ? c !== KnownCategories.FinraReviewed : true)
			);
			categories.splice(0, 0, KnownCategories.All);
			categories.splice(2, 0, KnownCategories.MyTemplates);
			if (isFinancial) {
				const indexOfFeatured = categories.findIndex(x => x === KnownCategories.Featured) || 0;
				categories.splice(indexOfFeatured + 1, 0, KnownCategories.FinraReviewed);
			}
			if (canSendHtmlNewsletters(userSession)) {
				categories.push(KnownCategories.HtmlNewsletters);
			}
			return categories;
		},
		queryKey: [EMAIL_CAMPAIGN_CATEGORIES_QUERY_KEY, industry, impersonationContext],
	});
};
