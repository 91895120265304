import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_API_KEY = 'contactSearch';

export function useContactSearchQuery({
	impersonationContext,
}: { impersonationContext?: Api.IImpersonationContext } = {}) {
	const userSession = useUserSession();
	return useQuery({
		queryFn: async () => {
			const data = await userSession.webServiceHelper.callAsync<Api.ContactSearch[]>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `contactSearch`,
					queryParams: { supportsOwnershipFilters: true },
					impersonationContext,
				}),
				'GET'
			);
			return data;
		},
		queryKey: [BASE_API_KEY],
	});
}
